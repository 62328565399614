import React, { useState, useRef, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import SignaturePad from "signature_pad";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "transparent",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: "#ffffff",
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const Signature = (props) => {
  const [completedTitle, setCompletedTitle] = useState(false);
  const [completedName, setCompletedName] = useState(false);
  const [completedSignature, setCompletedSignature] = useState(false);
  const [signatureImages, setSignatureImages] = useState({ svg: "", png: "" });
  const sigCanvas = useRef(null);
  const signaturePad = useRef(null);

  useEffect(() => {
    signaturePad.current = new SignaturePad(sigCanvas.current, {});
    window.onresize = resizeCanvas;

    signaturePad.current.onEnd = () => {
      setSignatureImages({
        svg: signaturePad.current.toDataURL("image/svg+xml"),
        png: signaturePad.current.toDataURL(),
      });
      setCompletedSignature(true);
    };

    resizeCanvas();
  }, []);

  useEffect(() => {
    if (completedTitle && completedName && completedSignature) {
      props.setCompletedSignedAgreement(true);
    } else {
      props.setCompletedSignedAgreement(false);
    }
  }, [completedTitle, completedName, completedSignature]);

  const resizeCanvas = () => {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);

    sigCanvas.current.width = sigCanvas.current.offsetWidth * ratio;
    sigCanvas.current.height = sigCanvas.current.offsetHeight * ratio;
    sigCanvas.current.getContext("2d").scale(ratio, ratio);
    handleClear();
  };

  const handleClear = () => {
    signaturePad.current.clear();
    setCompletedSignature(false);
  };

  const downloadAgreement = () => {
    if (props.selectedManufacturer == "boehringer_ingelheim") {
      return (
        <a
          href="/Boehringer Ingelheim - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Boehringer Ingelheim - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "glaxosmithkline") {
      return (
        <a
          href="/GSK - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="GSK - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "ucb") {
      return (
        <a
          href="/UCB - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="UCB - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "bristol_myers_squibb") {
      return (
        <a
          href="/Bristol Myers Squibb - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Bristol Myers Squibb - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "exelixis") {
      return (
        <a
          href="/Exelixis - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Exelixis - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "emd_serono") {
      return (
        <a
          href="/EMD Serono - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="EMD Serono - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "pfizer") {
      return (
        <a
          href="/Pfizer - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Pfizer - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "biogen") {
      return (
        <a
          href="/Biogen - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Biogen - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "novo_nordisk") {
      return (
        <a
          href="/Novo Nordisk - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Novo Nordisk - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "novartis") {
      return (
        <a
          href="/Novartis - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Novartis - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "sandoz") {
      return (
        <a
          href="/Sandoz - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Sandoz - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "sumitomo") {
      return (
        <a
          href="/Sumitomo - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Sumitomo - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    } else if (props.selectedManufacturer == "takeda") {
      return (
        <a
          href="/Takeda - Wholly Owned Contract Pharmacy Exemption Form.pdf"
          download="Takeda - Wholly Owned Contract Pharmacy Exemption Form.pdf"
        >
          <div className="btn btn--small btn--ib btn--pull-right" style={{ marginTop: 10 }}>
            Download Agreement
          </div>
        </a>
      );
    }
  };

  return (
    <div>
      <div className="register__form__dual-row register__form__dual-row--pad-right">
        <CustomTextField
          label="Title"
          margin="normal"
          variant="outlined"
          id="email"
          name="application[contact_title]"
          fullWidth
          required
          onChange={(event) => {
            if (event.target.value && event.target.value.length >= 2) {
              setCompletedTitle(true);
            } else {
              setCompletedTitle(false);
            }
          }}
        />
      </div>
      <div className="register__form__dual-row register__form__dual-row--pad-left">
        <CustomTextField
          label="Full Name"
          margin="normal"
          variant="outlined"
          id="email"
          name="application[signature_name]"
          fullWidth
          required
          onChange={(event) => {
            if (event.target.value && event.target.value.length >= 2) {
              setCompletedName(true);
            } else {
              setCompletedName(false);
            }
          }}
        />
      </div>
      <div className="register__form__requirements">
        <p>
          <span className="bold"> Please Sign below</span>
        </p>
      </div>
      <canvas
        ref={sigCanvas}
        style={{ width: "100%", height: 150, borderRadius: 12, backgroundColor: "#ffffff" }}
      ></canvas>
      <textarea
        name="application[signature_svg]"
        style={{ display: "none" }}
        defaultValue={signatureImages.svg}
      ></textarea>
      <textarea
        name="application[signature_png]"
        style={{ display: "none" }}
        defaultValue={signatureImages.png}
      ></textarea>

      <div className="btn btn--small btn--ib btn--grey" style={{ marginTop: 10 }} onClick={handleClear}>
        Clear
      </div>
      {downloadAgreement()}
    </div>
  );
};

export default Signature;
