import _, { map } from "lodash";
import { SHA3 } from "sha3";
import { schema } from "./Fields";
import Validations from "../Validations";
import moment from "moment";

export const filterData = (data, ndcList, mapping) => {
  var filtered = _.filter(data, (row) => _.find(ndcList, ["code", row[mapping["ndc"]]]));

  return filtered.map((row) => {
    var selectedData = _.pick(row, _.values(mapping));
    var trimData = _.mapValues(selectedData, (val) => val?.trim());

    // include additional keys for custom data
    trimData["claim_conforms_flag"] = null;
    trimData["formatted_rx_number"] = null;

    return trimData;
  });
};

export const generateSecureHash = (value, salt) => {
  // salt is 32 bit hexadecimal - 2 char per byte(64)

  // return early if no value, salt or incorrect salt character length
  if (!value || !salt || salt.length != 64) return;

  var hash = new SHA3(256).update(value + salt);

  return hash.digest("hex");
};

export const formatDate = (value) => {
  if (!moment(value, ["M/D/YY", "M/D/YYYY", "M-D-YYYY", "YYYY-M-D", "YYYY/M/D"], true).isValid()) return;

  // JS dates are off by 1 day when yyyy-mm-dd
  // convert date format to yyyy/mm/dd to ensure correct day date
  const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
  if (regex.test(value)) {
    value = _.replace(value, /-/g, "/");
  }

  // use US date format mm-dd-yyyy
  var dateString = new Date(value).toLocaleDateString("en-US", { year: "numeric", month: "2-digit", day: "2-digit" });

  // split formatted date
  var dateParts = dateString.split("/");

  if (dateParts.length === 3) {
    // convert to ISOString manually which will exclude timezone
    // ensure formatted date returned is yyyy-mm-dd
    return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`;
  } else {
    // invalid date format
    return false;
  }
};

export const normalizeRxNumber = (value, filesWithErrors, filePosition) => {
  if (!value) {
    filesWithErrors.push(filePosition);

    return false;
  }

  // remove fill count from rx_number - eg. 12345678-01
  var rx_number = value.split("-")[0];

  if (Validations.isValidRXNumber(rx_number)) {
    return removeLeadingZeros(rx_number);
  } else {
    filesWithErrors.push(filePosition);

    return false;
  }
};

export const removeLeadingZeros = (value) => {
  return value.replace(/^0+/, "");
};

export const isDateOfServiceWithinLastFortySixDays = (date) => {
  if (isNaN(Date.parse(formatDate(date)))) return;

  // The number of milliseconds in one day
  const timeInDay = 1000 * 60 * 60 * 24;

  const dateToday = formatDate(moment().format("MM/DD/YYYY"));
  const daysBetween = (Date.parse(dateToday) - Date.parse(formatDate(date))) / timeInDay;

  // daysBetween must be positive number
  if (daysBetween >= 0 && daysBetween <= 45) {
    return "true";
  } else {
    return "false";
  }
};

export const formatValue = (schemaColumnName, value, salt, row, mapping, ndcList, filesWithErrors, filePosition) => {
  if (schemaColumnName == "claim_conforms_flag") {
    var conforms_flag = isDateOfServiceWithinLastFortySixDays(formatDate(row[mapping["date_of_service"]]));

    return conforms_flag;
  }

  if (["date_of_service", "date_prescribed"].includes(schemaColumnName)) {
    return generateSecureHash(formatDate(value), salt);
  }

  if (["rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(value, salt);
  }

  if (["formatted_rx_number"].includes(schemaColumnName)) {
    return generateSecureHash(normalizeRxNumber(row[mapping["rx_number"]], filesWithErrors, filePosition), salt);
  }

  return value;
};

export const selectMapping = (mappings, data) => {
  const fileHeaders = _.keys(data[0]);
  var fieldMappingScores = [];

  _.forEach(mappings, (fieldMapping, index) => {
    var score = 0;

    _.forEach(fieldMapping.mappings, (value, key) => {
      fileHeaders.includes(value) ? (score += 1) : null;
    });

    fieldMappingScores[index] = { id: fieldMapping.id, score: score };
  });

  var scores = _.map(fieldMappingScores, "score");
  var mappingDetails = _.find(fieldMappingScores, (item) => item.score == Math.max(...scores));

  return _.find(mappings, ["id", mappingDetails.id]);
};

export const formatData = (fieldMapping, data, ndcList, salt, filesWithErrors, filePosition) => {
  var dataTrimmed = [];

  var filteredData = filterData(data, ndcList, fieldMapping);

  _.map(filteredData, (row) => {
    var schemaClone = _.cloneDeep(schema());

    _.map(schemaClone, (value, key) => {
      schemaClone[key] = formatValue(
        key,
        row[fieldMapping[key]],
        salt,
        row,
        fieldMapping,
        ndcList,
        filesWithErrors,
        filePosition
      );
    });

    dataTrimmed.push(schemaClone);
  });

  return dataTrimmed;
};

// Handle validations for data
const validationsNeeded = (schemaColumn, value, row, mapping) => {
  // if (["claim_conforms_flag", "formatted_rx_number"].includes(requiredColumn)) { return true }
  var isValid = false;
  switch (schemaColumn) {
    case "date_of_service":
      isValid =
        Validations.isValidDateOfService(value) &&
        !Validations.isQuestionableDateOfService(value, row[mapping["date_prescribed"]]);
      break;
    case "date_prescribed":
      isValid =
        Validations.isValidDatePrescribed(value) &&
        !Validations.isQuestionableDatePrescribed(value, row[mapping["date_of_service"]]);
      break;
    case "rx_number":
      isValid = Validations.isValidRXNumber(value);
      break;
    case "ndc":
      isValid = Validations.isValidNDC(value);
      break;
    case "quantity":
      isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);
      break;
    case "wholesaler_invoice_number":
      isValid = Validations.isValidWholesalerInvoiceNumber(value);
      break;
    case "prescriber_id_qualifier":
      isValid = Validations.isValidPrescriberIDQualifier(value);
      break;
    case "prescriber_id":
      isValid = Validations.isValidPrescriberID(row[mapping["prescriber_id_qualifier"]], value);
      break;
    case "service_provider_id_qualifier":
      isValid = Validations.isValidServiceProviderIDQualifier(value);
      break;
    case "service_provider_id":
      isValid = Validations.isValidServiceProviderID(row[mapping["service_provider_id_qualifier"]], value);
      break;
    case "contracted_entity_id":
      isValid = Validations.isValidContractedEntityID(value);
      break;
    default:
      isValid = false;
  }

  return isValid;
};

export const filesWithValidationErrors = (attachments, fieldMappings, ndcList) => {
  var filesWithErrors = [];

  // loop through all attachments to validate data
  _.forEach(attachments, (attachment) => {
    var mappingObj = _.find(fieldMappings, ["name", attachment.mappingName]);
    var filteredData = filterData(attachment.data, ndcList, mappingObj.mappings);

    if (filteredData.length == 0) {
      filesWithErrors.push(attachment.position);

      // return early if no filtered data is found
      return;
    }

    _.forEach(filteredData, (row) => {
      // move to next data attachment if attachment has errors
      if (filesWithErrors.includes(attachment.position)) {
        return;
      }

      _.forEach(mappingObj.mappings, (value, key) => {
        if (validationsNeeded(key, row[value], row, mappingObj.mappings) == false) {
          filesWithErrors.push(attachment.position);

          // return early if any data value is invalid
          return false;
        }
      });
    });

    // verify data to submit is hashed
    _.forEach(attachment.processedData, (row) => {
      // move to next data attachment if attachment has errors
      if (filesWithErrors.includes(attachment.position)) {
        return;
      }

      _.forEach(row, (value, key) => {
        switch (key) {
          case "date_of_service":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "date_prescribed":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "rx_number":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
          case "formatted_rx_number":
            if (filesWithErrors.includes(attachment.position)) {
              return;
            }

            if (!value || value.length != 64) {
              filesWithErrors.push(attachment.position);
            }
            break;
        }
      });
    });
  });

  return filesWithErrors;
};
