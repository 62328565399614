import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";

const AgreementEmdSerono = (props) => {
  const renderAgreement = () => {
    if (props.selectedManufacturer == "emd_serono") {
      return (
        <div>
          <div className="logo__container">
            <div className="logo">
              <i className="solid solid-budicon-pills" />
            </div>
            <div className="logo__info">
              <div className="logo__info__title">EMD Serono</div>
              <div className="logo__info__subtitle">Wholly Owned Contract Pharmacy Agreement</div>
            </div>
          </div>
          <p>
            For orders placed on or after October 1, 2023, wholesalers, on behalf of EMD Serono (EMDS), will ship EMDS
            marketed products purchased at the 340B ceiling price by hospital covered entities exclusively to locations
            registered within the Health Resources and Services Administration's (HRSA) database as a 340B covered
            entity or a child site location. Subject to the exceptions described below, pharmacies registered within the
            HRSA database as a contract pharmacy will no longer be eligible for Bill To / Ship To orders. To ensure all
            340B covered entities can access EMDS products at the 340B price, any covered entity that does not have an
            in-house pharmacy capable of dispensing 340B purchased drugs to its patients may designate a single contract
            pharmacy location by visiting www.340Besp.com/designations.
          </p>
          <p>
            Under EMDS's 340B distribution policy, contract pharmacies that are wholly owned by a 340B covered entity or
            have common ownership with a health system, will remain eligible to receive Bill To / Ship To orders of
            drugs purchased at the 340B ceiling price. These pharmacies must be registered within the HRSA database as a
            contract pharmacy of the 340B covered entity. If a covered entity or health system is granted an exemption
            from EMDS's 340B distribution policy for its wholly owned contract pharmacy(ies), it may not also designate
            an independent (i.e., third party) contract pharmacy, except with respect to Serostim®.
          </p>
          <p>
            Covered entities must complete the Wholly Owned Contract Pharmacy Exemption form on the 340B ESP website to
            apply for this exemption. Proof of ownership documents are required to complete your request. Examples of
            proof of ownership documents include:
          </p>
          <ul>
            <li>Certificate of liability insurance</li>
            <li>State business licenses</li>
          </ul>
          <p>
            Please note that failure to populate all required fields will delay the processing of Wholly Owned Contract
            Pharmacy Exemption requests. Exemptions will be valid for twelve (12) months from approval date and after
            the initial 12-month period, EMDS may require covered entities to resubmit proof of ownership documents to
            validate the continued eligibility of existing exemptions.
          </p>
          <p>
            By signing this form the authorized representative of the covered entity certifies that the covered entity
            and contract pharmacy(ies) included in this Exemption request: (i) have the same corporate ownership, OR
            (ii) that the covered entity wholly owns the identified contract pharmacy(ies); AND (iii) the covered entity
            qualifies for the exemption as described in this form. If there are any changes to the ownership structure
            of any of the contract pharmacies identified in this form, the covered entity agrees to notify Second Sight
            Solutions at support@340besp.com within five (5) business days of such change of ownership. EMDS reserves
            the right to modify or remove this exemption at any time upon notice.
          </p>
          <div className="register__divider" />
        </div>
      );
    } else {
      return null;
    }
  };

  return renderAgreement();
};

export default AgreementEmdSerono;
