export const numberWithCommas = (val) => {
  if (val == null) {
    return;
  }

  if (val.toString() == "0") {
    return "0";
  }

  if (val == false) {
    return "0";
  }

  if (val == true) {
    return "1";
  }

  return val
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const wholeNumberWithCommas = (val) => {
  if (val == null) {
    return;
  }

  if (val == false) {
    return "0";
  }

  if (val == true) {
    return "1";
  }

  if (val.toString() == "0") {
    return "0";
  }

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
