import React, { useRef, useState, useEffect } from "react";
import DataError from "./Errors";
import DataOutputOrginal from "./DataOutputOriginal";
import _ from "lodash";
import Validations from "../Validations";
import noTasks from "images/empty.png";
import { validationDescriptions } from "./ValidationDescriptions";
import { formatValue, filterData } from "./DataProcessing"
import DropdownSelectMapping from "./DropdownSelectMapping";

const DataOutput = (props) => {
  var descriptions = useRef(_.cloneDeep(validationDescriptions()));
  const [viewOriginal, setViewOriginal] = useState(false);
  const [displayNotice, setDisplayNotice] = useState(false);
  var isValidSubmission = useRef();
  var filteredDataRows = useRef(0);

  useEffect(() => {
    resetNotices();

    if (!isValidSubmission.current && props.data.length > 0) {
      setDisplayNotice(true)
      props.setSubmissionErrors();
    }

    if (props.data.length && displayColumnMappingWarning()) {
      props.displayTabBarTags(true)
    } else {
      props.displayTabBarTags(false)
    }

  }, [props.data]);

  const resetNotices = () => {
    descriptions.current = _.cloneDeep(validationDescriptions());
    setDisplayNotice(false)
  };

  const renderColumnMappingWarning = (columnName) => {
    if (["claim_conforms_flag", "formatted_rx_number"].includes(columnName)) { return }

    if (_.isEmpty(props.fieldMapping)) {
      return columnName
    }

    if (Validations.isValidColumnMapping(columnName, props.fieldMapping[columnName])) {
      return columnName
    } else {
      return <div>
        <div className="tag__info tag__info--lg tag__info--lg--orange" style={{ marginRight: 5, verticalAlign: 'middle' }}>
          <div className={columnName == "contracted_entity_id" ? "tooltip tooltip--bottom--last tooltip--fast" : "tooltip tooltip--bottom tooltip--fast"}>
            <div className="tooltip__container">
              <div className="tooltip__container__title">You mapped</div>
              <div className="tooltip__container__subtitle tooltip__container__subtitle--bold-orange">{props.fieldMapping[columnName]}</div>
            </div>
          </div>
        </div>
        {columnName}
      </div>
    }
  }

  const renderColumnHeaders = () => {
    return (
      <thead>
        <tr>
          {_.keys(props.requiredShape).map((columnName, i) => {
            if (["claim_conforms_flag", "formatted_rx_number"].includes(columnName)) { return }

            return (
              <th key={i} className="data-table__head__column">
                {renderColumnMappingWarning(columnName)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const displayColumnMappingWarning = () => {
    var validations = _.map(props.fieldMapping, (value, key) => Validations.isValidColumnMapping(key, value));

    return validations.includes(false);
  }

  const validationsNeeded = (requiredColumn, value, row, finalItem) => {
    if (["claim_conforms_flag", "formatted_rx_number"].includes(requiredColumn)) { return }

    var isValid = false;
    switch (requiredColumn) {
      case "date_of_service":
        isValid = Validations.isValidDateOfService(value) && !Validations.isQuestionableDateOfService(value, row[props.fieldMapping["date_prescribed"]]);
        var noticeDetails = _.find(descriptions.current, ["title", "Date of Service"]);

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "date_prescribed":
        isValid = Validations.isValidDatePrescribed(value) && !Validations.isQuestionableDatePrescribed(value, row[props.fieldMapping["date_of_service"]]);

        var noticeDetails = _.find(descriptions.current, ["title", "Date Prescribed"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "rx_number":
        isValid = Validations.isValidRXNumber(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Rx Number"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "ndc":
        isValid = Validations.isValidNDC(value);

        var noticeDetails = _.find(descriptions.current, ["title", "NDC"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "quantity":
        isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Quantity"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "wholesaler_invoice_number":
        isValid = Validations.isValidWholesalerInvoiceNumber(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Wholesaler Invoice Number"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "prescriber_id_qualifier":
        isValid = Validations.isValidPrescriberIDQualifier(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Prescriber ID Qualifier"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "prescriber_id":
        isValid = Validations.isValidPrescriberID(row[props.fieldMapping["prescriber_id_qualifier"]], value);

        var noticeDetails = _.find(descriptions.current, ["title", "Prescriber ID"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "service_provider_id_qualifier":
        isValid = Validations.isValidServiceProviderIDQualifier(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Service Provider ID Qualifier"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "service_provider_id":
        isValid = Validations.isValidServiceProviderID(row[props.fieldMapping["service_provider_id_qualifier"]], value);

        var noticeDetails = _.find(descriptions.current, ["title", "Service Provider ID"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      case "contracted_entity_id":
        isValid = Validations.isValidContractedEntityID(value);

        var noticeDetails = _.find(descriptions.current, ["title", "Contracted Entity ID"])

        if (!isValid && !noticeDetails.display) {
          noticeDetails.display = true;
        }
        break;
      default:
        isValid = false;
    }

    // once a validation is false dont allow subsequent rows to falsely create a valid submission
    if (isValid == false && isValidSubmission.current != false) {
      isValidSubmission.current = false;
    } else if (isValid == true && isValidSubmission.current != true) {
      isValidSubmission.current = true;
    }

    if (finalItem) {
      if (isValidSubmission.current) {
        descriptions.current = _.cloneDeep(validationDescriptions());
      }
    }

    return isValid;
  };

  const validateCellValue = (column, value, row, dataCount, columnCount, dataIndex, itemIndex) => {
    var finalItem = dataCount == dataIndex + 1 && columnCount == itemIndex + 1;

    return validationsNeeded(column, value, row, finalItem);
  };

  const renderCellValue = (columnName, row) => {
    if (["claim_conforms_flag", "formatted_rx_number"].includes(columnName)) { return }

    return formatValue(columnName, row[props.fieldMapping[columnName]], props.salt, row, props.fieldMapping)
  }

  const renderTableCell = (key, columnName, row, dataCount, columnCount, dataIndex, itemIndex) => {
    if (["claim_conforms_flag", "formatted_rx_number"].includes(key)) { return }

    return (
      <td key={key} className={validateCellValue(key, columnName, row, dataCount, columnCount, dataIndex, itemIndex) ? "data-table__row__item" : "data-table__row__item data-table__row__item--invalid"}>
        {renderCellValue(key, row)}
      </td>
    )
  }

  const renderRows = (data) => {
    var requiredShape = _.keys(props.requiredShape);
    var dataCount = data.length;
    var columnCount = requiredShape.length

    return (
      <tbody>
        {_.map(_.compact(data), (row, dataIndex) => (
          <tr key={dataIndex} className="data-table__row">
            {_.map(requiredShape, (key, itemIndex) => (
              renderTableCell(key, row[props.fieldMapping[key]], row, dataCount, columnCount, dataIndex, itemIndex)
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const renderData = () => {
    const dataCopy = _.cloneDeep(props.data);

    if (props.fieldMapping) {
      var filtered = filterData(dataCopy, props.ndcList, props.fieldMapping);
    }

    filteredDataRows.current = filtered.length;

    if (filtered.length > 0) {
      return (
        <table>
          {renderColumnHeaders()}
          {renderRows(filtered)}
        </table>
      );
    } else {
      return (
        <div>
          <table>{renderColumnHeaders()}</table>
          {renderEmptyNdcs()}
        </div>
      );
    }
  };

  const generateEmptyRow = (index) => {
    return (
      <tr key={index} className="data-table__row">
        <td colSpan={_.keys(props.requiredShape).length} />
      </tr>
    );
  };

  const renderEmpty = () => {
    return (
      <table>
        {renderColumnHeaders()}
        <tbody>{_.times(15, (i) => generateEmptyRow(i))}</tbody>
      </table>
    );
  };

  const renderEmptyNdcs = () => {
    return (
      <div className="data-table__message">
        <div className="data-table__message__image">
          <img src={noTasks} />
        </div>
        <div className="data-table__message__content">
          <div className="data-table__message__content__title">No Matching NDCs</div>
          <div className="data-table__message__content__subtitle">
            It looks like there are no matching NDCs in this file.
            <br />
            Please confirm that your <span style={{ fontWeight: 500, textDecoration: "underline" }}>Column Mappings</span> are correct.
          </div>
        </div>
      </div>
    );
  };

  const renderFile = () => {
    if (props.data.length) {
      return viewOriginal ? <DataOutputOrginal ndcList={props.ndcList} fieldMapping={props.fieldMapping} data={props.data} columns={props.columns} /> : renderData();
    } else {
      return renderEmpty();
    }
  };

  const renderNotice = () => {
    if (displayNotice) {
      if (!filteredDataRows.current > 0) {
        return renderMessageNothingToSubmit();
      } else {
        return <DataError errors={descriptions.current} />;
      }
    }
  };

  const renderMessageNothingToSubmit = () => {
    return (
      <div className="notify__banner">
        <div className="notify__banner__icon">
          <i className="solid solid-budicon-notification"> </i>
        </div>
        <div className="notify__banner__notice__title">
          <strong>Nothing to submit -</strong>
        </div>
        <div className="notify__banner__notice">It looks like there are no matching NDCs in this file.</div>
      </div>
    );
  };

  const renderWarnings = () => {
    if (displayColumnMappingWarning()) {
      return <div className="notify__error notify__error--warning animated fadeInUp">
        <div className="notify__error__col">
          <div className="notify__error__error-title notify__error__error-title--warning">Warning</div>
        </div>

        <div className="notify__error__col notify__error__col--spread">
          <div className="notify__error__description-title">Confirm Column Mappings</div>
          <div className="notify__error__description">Please ignore if you have confirmed that your mappings names are correct</div>
        </div>
      </div>
    }
  }

  return (
    <div>
      <div className="data-table__actionbar data-table__actionbar--space-between">
        <div>
          {renderWarnings()}
          {renderNotice()}
        </div>
        <div style={{ display: 'flex' }}>
          <DropdownSelectMapping
            mappings={props.mappings}
            attachments={props.attachments}
            selectedFile={props.selectedFile}
            setSelectedMapping={props.setSelectedMapping}
          />
          <div
            className="btn btn--outline btn--align-end btn--white"
            style={{ marginLeft: 20 }}
            onClick={() => {
              setViewOriginal(!viewOriginal);
            }}
          >
            <i className="solid solid-budicon-search-a" />
            {viewOriginal ? "View Submission" : "Inspect Original File"}
          </div>
        </div>
      </div>
      <div className="data-table__container">{renderFile()}</div>
    </div>
  );
};

export default DataOutput;
