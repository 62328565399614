import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { fields } from "./Fields";
import Validations from "../Validations";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    margin: "10px 0 20px 0",
    "& label.Mui-focused": {
      color: "#3D71FF"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DEE5EB",
      },
      "&:hover fieldset": {
        borderColor: "#3D71FF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      }
    },
  },
})(TextField);

const FieldMappings = (props) => {
  const [displayMappingsDropdown, setDisplayMappingsDropdown] = useState(_.map(props.mappings, (mapping) => ({ id: mapping.id, display: false })));
  const [displayDropDownForm, setDisplayDropDownForm] = useState(false);
  const inputRefValue = useRef();
  const schemaFields = _.cloneDeep(fields());
  const [loading, setLoading] = useState(_.map(props.mappings, (mapping) => ({ id: mapping.id, loading: false })))
  const [deleting, setDeleting] = useState(_.map(props.mappings, (details) => ({ id: details.id, items: _.map(details.mappings, (value, key) => ({ name: key, deleting: false })) })))

  // reset loading and deleteing state on updated mapping props
  useEffect(() => {
    setLoading(_.map(props.mappings, (mapping) => ({ id: mapping.id, loading: false })))
    setDeleting(_.map(props.mappings, (details) => ({ id: details.id, items: _.map(details.mappings, (value, key) => ({ name: key, deleting: false })) })))
  }, [props.mappings]);


  const createMapping = (inputValue) => {
    props.createMapping(inputValue)

    setDisplayDropDownForm(false)
  }

  const postUpdateMappingName = (id, inputValue) => {
    var cloneLoading = _.cloneDeep(deleting)
    var details = _.find(cloneLoading, ['id', id])
    details.loading = true

    setLoading(cloneLoading)
    props.updateFieldMapping(id, "name", inputValue)

    setMappingEditFormDisplay(id)
  }

  const removeMapping = (id, fieldName, mappingName) => {
    var cloneDeleting = _.cloneDeep(deleting)
    var details = _.find(_.find(cloneDeleting, ['id', id]).items, ['name', fieldName])
    details.deleting = true

    setDeleting(cloneDeleting)
    props.deleteFieldMapping(id, fieldName, mappingName)
  }

  const renderLoading = (mappingID) => {
    var loadingDetails = _.find(loading, ['id', mappingID])

    if (loadingDetails && loadingDetails.loading) {
      return <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin"></div>
    } else {
      return <div className="solid solid-budicon-pen-writing"></div>
    }
  }

  const renderDeleting = (id, fieldName) => {
    var deletingDetails = _.find(_.find(deleting, ['id', id]).items, ['name', fieldName])

    if (deletingDetails && deletingDetails.deleting) {
      return <div className="loading-indicator loading-indicator--blue loading-indicator--no-margin"></div>
    } else {
      return <div className="solid solid-budicon-trash" />
    }
  }

  const renderMappingTag = (id, fieldName, mappingName) => {
    if (mappingName) {
      return <div key={id} className="mapping__group__assignment__dropzone__items">
        <div className={Validations.isValidColumnMapping(fieldName, mappingName) ? "tag tag--outline-bg-white tag--max-height tag--square tag--lowercase" : "tag tag--outline-bg-white tag--outline-bg-white--warning tag--max-height tag--square tag--lowercase"}>
          {mappingName}
        </div>
        <div className="btn mapping__group__assignment__dropzone__items__btn-action btn--default-align" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { removeMapping(id, fieldName, mappingName) }}>
          {renderDeleting(id, fieldName)}
        </div>
      </div>
    }
  }

  const renderExistingMappings = (id, fieldName, fieldMapping) => {
    return <div key={fieldName + id} className="mapping__group__column">
      <div className="mapping__group__assignment__container" id={fieldName} data-fieldname={fieldName}>
        <div className="mapping__group__assignment__title">
          {fieldName}
        </div>
        <Droppable droppableId={`existing#${fieldName}#${id}`}>
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="mapping__group__assignment__dropzone" style={snapshot.isDraggingOver ? { borderColor: "#3246D3" } : null}>
              {renderMappingTag(id, fieldName, fieldMapping)}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </div>
  }

  const hasColumnWarning = (columnName) => {
    var selectedMappings = _.map(props.mappings, (mappingDetails) => mappingDetails.mappings[columnName]);
    var validations = _.map(selectedMappings, (value, key) => Validations.isValidColumnMapping(columnName, value))

    return validations.includes(false)
  }

  const renderSchema = () => {
    const orderedFields = schemaFields.sort((a, b) => a.title.localeCompare(b.title))

    return orderedFields.map((field, index) => (
      <div key={index} className="mapping__group__row">
        <div className="mapping__group__column">
          <div className={hasColumnWarning(field.name) ? "mapping__group__item mapping__group__item--warning" : "mapping__group__item"}>
            {field.required ? <div className="mapping__group__item--required">required</div> : null}
            <div className="mapping__group__item__title">
              {field.title}
            </div>
            <div className="mapping__group__item__subtitle">
              {field.description}
            </div>
          </div>
        </div>
        { props.mappings.map(fieldMapping => renderExistingMappings(fieldMapping.id, field.name, fieldMapping.mappings[field.name]))}
      </div>
    ))
  }

  const renderDropDownEditForm = (id) => {
    var mapping = _.find(displayMappingsDropdown, ['id', id])

    if (mapping && mapping.display) {
      return <div className="dropdown__container">
        <div className="dropdown__header">
          <div className="dropdown__header__title">Edit mapping name</div>
          <div className="dropdown__header__close" onClick={() => setMappingEditFormDisplay(id)}>
            <div className="solid solid-budicon-cross-ui" />
          </div>
        </div>
        <CustomTextField
          label="Mapping Name"
          margin="none"
          variant="outlined"
          autoComplete="off"
          size="small"
          id={`mappingName-${id}`}
          onChange={(el) => inputRefValue.current = el.target.value}
          name="field_mappings[name][id]"
          fullWidth
          required
        />
        <div className="btn btn--small" onClick={() => postUpdateMappingName(id, inputRefValue.current)}>Edit Name</div>
      </div>
    }
  }

  const setMappingEditFormDisplay = (id) => {
    var cloneMappingDisplay = _.cloneDeep(displayMappingsDropdown)
    var mapping = _.find(cloneMappingDisplay, ['id', id])

    mapping.display = !mapping.display

    setDisplayMappingsDropdown(cloneMappingDisplay)
  }

  const renderHeaders = () => {
    return props.mappings.map((fieldMapping, index) =>
      <div key={index} className="mapping__group__column">
        <div className="mapping__group__header mapping__group__header--padding-sm">
          <div className="btn btn--sq btn--outline-subtle-white btn--small--fixed btn--default-align btn--lowercase" style={{ fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { setMappingEditFormDisplay(fieldMapping.id) }}>
            {renderLoading(fieldMapping.id)}
          </div>
          {fieldMapping.name ? fieldMapping.name : `Mapping-${index + 1}`}
          <div className="btn btn--outline-subtle-white btn--small--fixed btn--default-align btn--lowercase" style={{ fontSize: 12 }} onClick={() => { props.deleteAllFieldMappings(fieldMapping.id) }}>Delete All</div>
        </div>
        {renderDropDownEditForm(fieldMapping.id)}
      </div>
    )
  }

  const renderDropDownForm = () => {
    if (displayDropDownForm) {
      return <div className="dropdown__container">
        <div className="dropdown__header">
          <div className="dropdown__header__title">Mappings</div>
          <div className="dropdown__header__close" onClick={() => setDisplayDropDownForm(false)}>
            <div className="solid solid-budicon-cross-ui" />
          </div>
        </div>
        <CustomTextField
          label="Mapping Name"
          margin="normal"
          variant="outlined"
          id="mappingName"
          size="small"
          autoComplete="off"
          onChange={(el) => inputRefValue.current = el.target.value}
          name="field_mappings[name]"
          fullWidth
          required
        />
        <div className="btn btn--small" onClick={() => createMapping(inputRefValue.current)}>Create Mapping</div>

      </div>
    }
  }

  const renderNewFieldMappingsBtn = () => {
    return <div className="mapping__group__column">
      <div className="mapping__group__header mapping__group__header--outline" onClick={() => { setDisplayDropDownForm(!displayDropDownForm) }}>
        <i className="budicon-plus-sign-square" />
        New mapping
      </div>
      {renderDropDownForm()}
    </div>
  }

  return (
    <div className="pill__container">
      <div className="mapping__container">
        <div className="mapping__group__row">
          <div className="mapping__group__column">
            <div className="mapping__group__header">Columns</div>
          </div>
          {renderHeaders()}
          {renderNewFieldMappingsBtn()}
        </div>
        {renderSchema()}
      </div>
    </div>
  );
};

export default FieldMappings;
