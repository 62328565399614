import React, { useState, useEffect } from "react";
import _ from "lodash";
import Validations from "../Validations";

const DataOutputOriginal = (props) => {
  const validationsNeeded = (requiredColumn, value, row) => {
    var isValid = false;

    row = _.mapValues(row, (val) => val?.trim());

    switch (requiredColumn) {
      case "date_of_service":
        isValid =
          Validations.isValidDateOfService(value) &&
          !Validations.isQuestionableDateOfService(value, row[props.fieldMapping["date_prescribed"]]);
        break;
      case "date_prescribed":
        isValid =
          Validations.isValidDatePrescribed(value) &&
          !Validations.isQuestionableDatePrescribed(value, row[props.fieldMapping["date_of_service"]]);
        break;
      case "rx_number":
        isValid = Validations.isValidRXNumber(value);
        break;
      case "ndc":
        isValid = Validations.isValidNDC(value);
        break;
      case "quantity":
        isValid = Validations.isValidQuantity(value) && !Validations.isQuestionableQuantity(value);
        break;
      case "wholesaler_invoice_number":
        isValid = Validations.isValidWholesalerInvoiceNumber(value);
        break;
      case "prescriber_id_qualifier":
        isValid = Validations.isValidPrescriberIDQualifier(value);
        break;
      case "prescriber_id":
        isValid = Validations.isValidPrescriberID(row[props.fieldMapping["prescriber_id_qualifier"]], value);
        break;
      case "service_provider_id_qualifier":
        isValid = Validations.isValidServiceProviderIDQualifier(value);
        break;
      case "service_provider_id":
        isValid = Validations.isValidServiceProviderID(row[props.fieldMapping["service_provider_id_qualifier"]], value);
        break;
      case "contracted_entity_id":
        isValid = Validations.isValidContractedEntityID(value);
        break;
      default:
        isValid = false;
    }

    return isValid;
  };

  const renderHeaders = () => {
    return (
      <thead>
        <tr>
          {props.columns.map((header, i) => {
            return (
              <th
                key={i}
                className={
                  _.values(props.fieldMapping).includes(header)
                    ? "data-table__head__column data-table__head__column--mapped"
                    : "data-table__head__column"
                }
              >
                {header}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderSelectedRows = (row, key, index) => {
    if (!props.fieldMapping || !row[props.fieldMapping["ndc"]]) {
      return "data-table__row__item";
    }

    const ndcs = _.map(props.ndcList, (list) => list.code);

    if (ndcs.includes(row[props.fieldMapping["ndc"]]) && _.values(props.fieldMapping).includes(key)) {
      var schemaColumnName = _.findKey(props.fieldMapping, (val) => val == key);
      return validationsNeeded(schemaColumnName, row[key], row)
        ? "data-table__row__item data-table__row__item--mapped"
        : "data-table__row__item data-table__row__item--invalid";
    } else if (ndcs.includes(row[props.fieldMapping["ndc"]])) {
      return "data-table__row__item data-table__row__item--selected";
    } else {
      return "data-table__row__item";
    }
  };

  const renderOriginalRows = () => {
    return (
      <tbody>
        {_.map(props.data, (row, i) => (
          <tr key={i} className="data-table__row">
            {_.map(row, (column, key) => {
              return (
                <td key={key} className={renderSelectedRows(row, key, i)}>
                  {column}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  };

  const renderOriginalData = () => {
    if (props.data.length) {
      return (
        <table>
          {renderHeaders()}
          {renderOriginalRows()}
        </table>
      );
    }
  };

  return renderOriginalData();
};

export default DataOutputOriginal;
