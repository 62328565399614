import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const SerostimDesignationsForm = (props) => {
  const [specialtyDesignation, setSpecialtyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].specialtyDesignationForm = {
      open: specialtyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [specialtyDesignation, hasSelectedPharmacy]);

  const policies = () => {
    if (props.isBlocked) {
      return notPermitted();
    }

    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>
            EMD Serono permits a 340B hospital covered entity to use a single designated contract pharmacy location
            within EMD Serono's secured distribution network for 340B purchases of Serostim® if that covered entity does
            not have the ability to dispense 340B purchased Serostim to eligible patients at an in-house pharmacy. The
            designated contract pharmacy location must be registered and active in HRSA's 340B OPAIS database and must
            be within (or apply for entry and be accepted into) EMD Serono's secured distribution network for Serostim.
            By designating a contract pharmacy on this web form, the authorized representative of the 340B covered
            entity is attesting that the 340B covered entity does not have an in-house pharmacy that is capable of
            dispensing 340B purchased Serostim to eligible patients.
            <br />
            <br />
            Covered entities may be required to resubmit contract pharmacy location designations every 12 months.
            Covered entities may change contract pharmacy location designations within a 12-month period only if the
            designated contract pharmacy location is terminated as a contract pharmacy of the covered entity from the
            340B OPAIS database.
          </span>
          {renderConfirmation()}
        </div>
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s secured distribution product, Serostim?`;
  };

  const renderDesignationSubtitle = () => {
    return "Contract Pharmacy Designation";
  };

  const renderSpecialtyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">{renderDesignationSubtitle()}</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={specialtyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSpecialtyDesignation(true)}
              >
                yes
              </div>
              <div
                className={specialtyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSpecialtyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const getPolicyConfirmationMessage = () => {
    return "Please confirm policy before assigning designation";
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          {getPolicyConfirmationMessage()}
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Confirm
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const notPermitted = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="stat-cards__confirmation stat-cards__confirmation--disabled">
          <span>Please reach out to support at support@340besp.com for assistance.</span>
          {renderNotPermitted()}
        </div>
      </div>
    );
  };

  const renderNotPermitted = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3 }}></div>
          Contact support
        </span>
      </div>
    );
  };

  const renderPolicy = () => {
    if (specialtyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Policy Confirmation</div>
          <div className="designation_form__section__group__step__subtitle">
            Please confirm manufacturer's policy to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const renderNoticeHasExistingDesignation = (entity) => {
    var designation = entity.designations_count[props.manufacturer];

    if (designation && designation[props.specialtyKeyName] > 0) {
      return (
        <div className="stat-cards__notice stat-cards__notice--blue stat-cards__notice--mb" style={{ lineHeight: 1.5 }}>
          The selected covered entity already made a contract pharmacy designation, please login to or register an
          account with 340B ESP to view the details of this designation. If you have questions, please reach out to
          support@340besp.com
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return _.filter(entity.pharmacies.details, { [props.manufacturer]: [props.specialtyKeyName] });
  };

  const renderPharmacySelect = () => {
    // hasSelectedSpecialtyPharmacy is passed in to render notice
    // You can't select two specialty pharmacies across designation kinds per manufacturer

    // retailSelectedSpecialtyPharmacy for duplicate input values if single designation has selected specialty CP

    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        {renderNoticeHasExistingDesignation(entity)}
        <PharmacySelect
          kind={props.specialtyKeyName}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={false}
          disableWhollyOwnedNotice={false}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={true}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          retailSelectedSpecialtyPharmacy={props.retailSelectedSpecialtyPharmacy}
          retailHasSelectedSpecialtyPharmacy={props.retailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (specialtyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSpecialtyDesignationForm();
};

export default SerostimDesignationsForm;
