export const mapManufacturersForSelect = (manufacturers) => {
  const NUM_GROUPS = 4;

  const manufacturersGroupedByStartingLetter = _.groupBy(
    manufacturers.slice(1),
    (manufacturer) => manufacturer.label[0]
  );

  const optionsFormattedForMenu = Object.keys(manufacturersGroupedByStartingLetter).map((key) => ({
    label: key,
    options: manufacturersGroupedByStartingLetter[key].map(({ value, label }) => ({ value, label })),
  }));

  const divideDataIntoGroups = (data, NUM_GROUPS) => {
    const groupCounts = Math.ceil(_.keys(manufacturersGroupedByStartingLetter).length / NUM_GROUPS);

    let groups = [];
    let group = [];

    data.forEach((option, index) => {
      group.push(option);

      if (group.length == groupCounts) {
        groups.push(group);
        group = [];
      }
    });

    // ensure last group is pushed into groups list
    if (group.length) {
      groups.push(group);
    }

    return groups;
  };

  return divideDataIntoGroups(optionsFormattedForMenu, NUM_GROUPS);
};
