import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import * as moment from "moment";
import _ from "lodash";

const CalendarCard = (props) => {
  const [date, setDate] = useState(new Date());

  const handleChange = (date) => {
    setDate(date);
  };

  return (
    <div style={{ maringBottom: 30 }}>
      <Calendar
        value={null}
        prev2Label={null}
        next2Label={null}
        minDetail={"month"}
      />
    </div>
  );
};

export default CalendarCard;
