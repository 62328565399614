import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import PharmacySelect from "./PharmacySelect";

const RetailDesignationsForm = (props) => {
  const [singlePharmacyDesignation, setSinglePharmacyDesignation] = useState(false);
  const [confirmedPolicy, setConfirmedPolicy] = useState(false);
  const [hasSelectedPharmacy, setHasSelectedPharmacy] = useState(false);
  const [selectedPharmacy, setSelectedPharmacy] = useState(false);

  useEffect(() => {
    var formDetails = _.cloneDeep(props.manufacturerFormDetails);

    // set form details for completed status - obj name as class
    formDetails[props.manufacturer].singlePharmacyDesignationForm = {
      open: singlePharmacyDesignation,
      completed: hasSelectedPharmacy,
    };
    props.setManufacturerFormDetails(formDetails);
  }, [singlePharmacyDesignation, hasSelectedPharmacy]);

  const policies = () => {
    return (
      <div
        className={
          confirmedPolicy
            ? "designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--closed"
            : "designation_form__section__group__step__toggle_container"
        }
      >
        <div className="designation_form__section__group__step__toggle_container designation_form__section__group__step__toggle_container--scroll stat-cards__confirmation stat-cards__confirmation--disabled">
          NON-IMiD and CAMZYOS® DISTRIBUTION THROUGH THIRD-PARTY PHARMACY SUBMITTED DATA TERMS OF USE
          <ol>
            <li>
              Enrollment in 340B ESP™. A 340B covered entity “Purchaser” that seeks to acquire a non-immunomodulatory
              (Non-IMiD”) or Camzyos® covered outpatient drug subject to Bristol-Myers Squibb's (“BMS”) offer of 340B
              pricing (“340B Drug”) through a third- party pharmacy must enroll in 340B ESP™ and submit the specified
              BMS Claims Data (as defined below) through the Covered Entity Portal.
            </li>
            <li>
              Terms of Use. Purchaser that submits BMS Claims Data to the 340B ESP™ platform must agree to the 340B ESP™
              Terms of Use (“Platform Terms”) as well as the additional Terms of Use included herein (“BMS Terms”)
              (collectively “Combined Terms”). Specifically, the Combined Terms apply when Purchaser elects to have a
              340B Drug dispensed through a third-party pharmacy that Purchaser hereby attests is registered for such
              use in the Office of Pharmacy Affairs Information System (“OPAIS”), is the Purchaser's legally authorized
              agent at all relevant times to time of dispense for the 340B Drug, and is validly designated with BMS
              pursuant to BMS's 340B practice in effect at the time of dispense, including attestation that Purchaser
              does not have a Purchaser-owned pharmacy capable of dispensing the applicable 340B Drug (“Contract
              Pharmacy”). For avoidance of doubt, the Platform Terms extend and apply to any additional data and uses of
              data required by the BMS Terms.
            </li>
            <li>
              Prevailing Terms. These Terms are intended to be consistent with the Platform Terms, available at
              340besp.com/terms-of-use. If these Terms are found to be inconsistent with any provisions of the Platform
              Terms, these Terms will prevail over the Platform Terms with respect to the inconsistent provisions only.
              All other terms and conditions of the Platform Terms shall remain in full force and effect and are hereby
              ratified and confirmed by Purchaser.
            </li>
            <li>
              340B Purchase Integrity. With every Contract Pharmacy designation and BMS Claims Data submission to the
              340B ESP™ platform, Purchaser attests that it remains responsible for and maintains title to the 340B Drug
              during all points from Purchaser's acquisition until dispensing to Purchaser's eligible patient, and that
              Purchaser ensures compliance with all legal requirements, including the federal statutory diversion and
              duplicate discount prohibitions (42 U.S.C. § 256b(a)(5)(A)-(B)) and any other applicable requirements such
              as the Drug Supply Chain Security Act and state distribution and wholesaler licensing restrictions.
              Purchaser attests to shipment of the 340B Drug only to the same appropriately designated location of
              actual dispense of the 340B Drug and submission of data reflecting only such shipment, and acknowledges
              that any drug product subject to other, non-transparent arrangements affecting BMS's offer of 340B pricing
              (for example, but not limited to, “banking” orders outside of the reasonable window below, virtual return,
              alternative distribution, virtual credit) falls outside these BMS Terms, may not qualify as a 340B Drug,
              and may result in chargeback reversal or other action.
            </li>
            <li>
              Data Submission. Purchaser that designates Contract Pharmacy will submit to the 340B ESP™ platform BMS
              Claims Data (Section 7 below) beginning no later than 10 to 15 days after the Contract Pharmacy
              designation process is complete (“Designation Effective Date”). Purchaser must communicate to BMS all BMS
              Claims Data (see Section 7) within 14 days of dispense to the patient. Acknowledging that the U.S.
              Department of Health and Human Services (“HHS”) prohibits reclassifying a non-340B purchase as
              340B-eligible after the fact, Purchaser attests that it will only request or otherwise seek 340B pricing
              at the time of the original purchase. Purchaser further attests that all BMS Claims Data will be accurate
              and complete, and that Purchaser will notify BMS promptly upon the discovery of any inaccuracy or lack of
              completed BMS Claims Data in Purchaser's submission. Purchaser acknowledges that data submissions that do
              not meet the above criteria may result in chargeback reversal or other action.
            </li>
            <li>
              Confidentiality. Purchaser will use the 340B ESP™ platform to ensure transmission confidentiality. BMS and
              its technology platform provider will use commercially reasonable security measures to protect BMS Claims
              Data received pursuant to these Combined Terms. BMS and its technology platform provider will maintain in
              confidence and not disclose to third parties any non-public information it may receive as a result of the
              submission of claims-level data by Purchaser, except as may be required by law or these Combined Terms.
              BMS and its technology platform provider may disclose the BMS Claims Data received under these Combined
              Terms to contractors performing services to BMS, as long as such contractors are under obligations of
              confidentiality similar to those in these Combined Terms.
            </li>
            <li>
              Claims-Level Data. Purchaser will provide the following data elements with each 340B Drug order, as
              reflected in the Combined Terms applicable at the time of dispense, which include but are not limited to
              those specified in the Platform Terms and as required by the BMS Terms (collectively referred to as “BMS
              Claims Data”):
              <ul>
                <li>Contracted Entity ID/340B ID Number</li>
                <li>Date of Service/Date of Dispense</li>
                <li>Date Prescribed</li>
                <li>NDC-11</li>
                <li>Quantity</li>
                <li>Rx Number</li>
                <li>Service Provider ID</li>
                <li>Service Provider ID Qualifier</li>
                <li>Prescriber ID (upon platform capability)</li>
                <li>Prescriber ID Qualifier (upon platform capability)</li>
                <li>Wholesaler Invoice Number (upon platform capability)</li>
                <li>Payer BIN, PCN, GRP (upon platform capability)</li>
                <li>Ship to Date (upon platform capability)</li>
                <li>Ship to Location (upon platform capability)</li>
                <li>340B Account Number (upon platform capability)</li>
                <li>Product Serialization number (upon platform capability)</li>
              </ul>
            </li>
            <li>
              Data Use. BMS requests and will use BMS Claims Data for the purpose of supporting 340B program integrity.
              BMS will review BMS Claims Data to ensure that purchases comply with applicable law, including the
              statutory diversion and duplicate discount prohibitions; that only one 340B discount is paid on a single
              transaction; and that purchases are otherwise consistent with BMS's offer to Purchaser, including
              consistency with BMS's distribution practices and these BMS Terms. Purchaser represents and warrants that
              Purchaser has all necessary rights, title license and authority (including all necessary permissions from
              third party owners and rights holders) to provide BMS Claims Data as set forth herein. Purchaser grants
              BMS a worldwide, sublicensable, non-exclusive, royalty-free, perpetual, irrevocable license to collect,
              process, disclose, create derivative works for and otherwise use the BMS Claims Data for the purposes set
              forth in the Combined Terms.
            </li>
            <li>
              BMS reserves the right to alter these Terms at any time and may do so as necessary to safeguard 340B
              program integrity.
            </li>
          </ol>
          BY CLICKING THE “ACCEPT” BUTTON, OR OTHERWISE AFFIRMING ACCEPTANCE YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND
          UNDERSTAND THESE TERMS; (B) REPRESENT THAT YOU HAVE THE AUTHORITY TO ACCEPT THESE TERMS ON BEHALF OF THE
          COVERED ENTITY BY WHOM YOU ARE EMPLOYED OR ACTING AS AN AGENT TO PROVIDE COVERED ENTITY CLAIMS DATA, OR ON
          BEHALF OF YOUR EMPLOYER OR PRINCIPAL IF A LEGAL ENTITY OTHER THAN THE COVERED ENTITY, AND THAT ACCEPTANCE OF
          THESE TERMS HAVE BEEN DULY AUTHORIZED BY THE COVERED ENTITY AND YOUR EMPLOYER; AND (C) YOU ACCEPT THESE TERMS
          AND AGREE THAT YOU, THE COVERED ENTITY, AND YOUR EMPLOYER OR PRINCIPAL ARE LEGALLY BOUND BY ITS TERMS. IF YOU
          DO NOT AGREE TO THESE TERMS OR DO NOT HAVE SUCH AUTHORITY, DO NOT USE THE COVERED ENTITY PLATFORM.
          <br />
          <br />
          QUESTIONS:
          <br />
          <br />
          Email: bms340B@bms.com
        </div>
        {renderConfirmation()}
      </div>
    );
  };

  const renderDesignationQuestion = () => {
    return `Would you like to make a single contract pharmacy designation for ${props.manufacturerName}'s non-IMiD products?`;
  };

  const renderDesignationSubtitle = () => {
    return "Designation for Non-IMiD Products";
  };

  const renderSinglePharmacyDesignationForm = () => {
    return (
      <div className="designation_form__section__group">
        <div className="designation_form__section__group__row">
          <div className="designation_form__section__group__question">
            <div className="designation_form__section__group__question__subtitle">{renderDesignationSubtitle()}</div>
            {renderDesignationQuestion()}
          </div>
          <div className="designation_form__section__group__value">
            <div className="btn__option__group">
              <div
                className={singlePharmacyDesignation ? "btn btn--small btn--selected" : "btn btn--small"}
                onClick={() => setSinglePharmacyDesignation(true)}
              >
                yes
              </div>
              <div
                className={singlePharmacyDesignation ? "btn btn--small" : "btn btn--small btn--selected"}
                onClick={() => setSinglePharmacyDesignation(false)}
              >
                no
              </div>
            </div>
          </div>
        </div>
        {renderPolicy()}
        {renderStep()}
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="stat-cards__confirmation__footer">
        <span>
          <div className="indicator indicator--red indicator--pulse" style={{ marginTop: 3, marginLeft: 10 }}></div>
          Please accept Terms of Use before assigning designation
        </span>
        <span>
          <div className="btn btn--ib btn--tiny" onClick={() => setConfirmedPolicy(true)}>
            Accept
          </div>
        </span>
      </div>
    );
  };

  const renderStepIndicator = (step) => {
    if (step) {
      return (
        <div className="designation_form__section__group__step__indicator designation_form__section__group__step__indicator--complete">
          <i className="solid solid-budicon-check-ui" />
        </div>
      );
    } else {
      return <div className="designation_form__section__group__step__indicator"></div>;
    }
  };

  const renderPolicy = () => {
    if (singlePharmacyDesignation) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(confirmedPolicy)}
          <div className="designation_form__section__group__step__title">Terms of Use</div>
          <div className="designation_form__section__group__step__subtitle">
            Please accept manufacturers Terms of Use to continue.
          </div>
          {policies()}
        </div>
      );
    }
  };

  const filterPharmacies = (entity) => {
    return entity.pharmacies.details;
  };

  const renderPharmacySelect = () => {
    return _.map(props.entities, (entity, index) => (
      <div key={index}>
        <PharmacySelect
          kind={"retail"}
          entity={entity}
          pharmacies={filterPharmacies(entity)}
          manufacturer={props.manufacturer}
          disableGranteeNotice={true}
          disableWhollyOwnedNotice={true}
          disableInHouseSpecialtyNotice={true}
          disableInHouseNotice={false}
          selectedPharmacy={selectedPharmacy}
          setSelectedPharmacy={setSelectedPharmacy}
          setHasSelectedPharmacy={setHasSelectedPharmacy}
          setRetailSelectedSpecialtyPharmacy={props.setRetailSelectedSpecialtyPharmacy}
          setRetailHasSelectedSpecialtyPharmacy={props.setRetailHasSelectedSpecialtyPharmacy}
        />
      </div>
    ));
  };

  const renderStep = () => {
    if (singlePharmacyDesignation && confirmedPolicy) {
      return (
        <div className="designation_form__section__group__step">
          {renderStepIndicator(hasSelectedPharmacy)}
          <div className="designation_form__section__group__step__title">Select Contract Pharmacy</div>
          <div className="designation_form__section__group__step__subtitle">
            Please select a contract pharmacy to designate.
          </div>
          {renderPharmacySelect()}
        </div>
      );
    }
  };

  return renderSinglePharmacyDesignationForm();
};

export default RetailDesignationsForm;
