import React, { useState, useEffect } from "react";

const EntityDropDownSelector = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const renderEntities = () => {
    return (
      props.entities.map((entity, index) => {
        return (
          <a key={index} href={props.path + `/${entity.id}`}>
            <div className="entities__selector__dropdown_menu__item">
              <div className="entities__selector__dropdown_menu__item__icon">S</div>
              <div className="entities__selector__dropdown_menu__item__content">
                <div className="entities__selector__dropdown_menu__item__content__title">{entity.entity_name}</div>
                <div className="entities__selector__dropdown_menu__item__content__subtitle">{entity.id_340b}</div>
              </div>
            </div>
          </a>
        )
      })
    )
  }

  const renderDropdownMenu = () => {
    if (dropdownOpen) {
      return (
        <div className="entities__selector__dropdown_menu">
          <div className="entities__selector__dropdown_menu__title">Covered Entities</div>
          {renderEntities()}
        </div>
      )
    }
  }

  const renderSwitchBtnLabel = () => {
    if (props.entities.length > 1) {
      return (
        <div className="entities__item__controls">
          switch
          <i className="solid solid-budicon-chevron-bottom" style={{ marginLeft: 5, fontWeight: 800 }} />
        </div>
      )
    }
  }

  return (
    <div className="details__header__section details__header__section--padding">
      <div className="entities__selector" onClick={() => { if (props.entities.length > 1) { setDropdownOpen(!dropdownOpen) } }}>
        <div className="entities__item">
          <div className="entities__item__icon">{props.entity.entity_name[0]}</div>
          <div className="entities__item__details">
            <div className="entities__item__details__title">{props.entity.entity_name}</div>
            <div className="entities__item__details__subtitle">{props.entity.address}</div>
          </div>
          {renderSwitchBtnLabel()}
        </div>

        {renderDropdownMenu()}
      </div>
    </div>
  );
};

export default EntityDropDownSelector;
