import React, { Component } from "react";
import SubmissionActionDetails from "./SubmissionDetailsCard";
import CalendarCard from "./CalendarCard";

const Draw = (props) => {
  return (
    <div>
      <div className="draw__item__container" style={{ marginBottom: 30 }}>
        <SubmissionActionDetails
          organization={props.organizationID}
          atRisk={props.atRisk}
          pendingRegistrations={props.pendingRegistrations}
          pendingRegistrationIDs={JSON.parse(props.pendingRegistrationIDs)}
          entity={props.entity}
          transferDetails={props.transfer_details}
        />
      </div>
      <div className="draw__item__container">
        <CalendarCard organization={props.organizationID} />
      </div>
    </div>
  );
};

export default Draw;
