import React from "react";

const TermsOfService = () => {
  return (
    <div className="customized-list">
      <p>
        Welcome to 340B ESP™, provided by Second Sight Solutions LLC and its affiliates (“Second Sight,” “Company,”
        “we,” “us,” “our”). These legally binding Terms of Use, as may be amended by us from time to time (“Terms”),
        apply to the 340B ESP™ Covered Entity website portal accessed at www.340besp.com/register and
        www.340besp.com/login (our “Covered Entity Portal”), and associated web pages and the 340B ESP™ offering made
        available therein (together with any updates provided by Second Sight and any corresponding documentation,
        associated media, printed materials, and online or electronic documentation) (collectively, the “Covered Entity
        Platform”).
      </p>
      <p>
        340B ESP™ enables a 340B covered entity to submit certain de-identified 340B pharmacy claims data to Second
        Sight. Second Sight then enables analysis of this data for pharmaceutical manufacturers in order to identify
        duplicate Medicaid, Medicare, TriCare and commercial payer rebates that are not eligible for reimbursement by
        Participating Pharmaceutical Manufacturers (defined below) (“Ineligible Rebates”). For additional information
        about the Covered Entity Platform, please see our Frequency Asked Questions (“FAQs”), available online at
        www.340besp.com/faqs.
      </p>
      <p>
        Please read and review these Terms carefully before accessing or using the Covered Entity Platform. In order to
        use the Covered Entity Platform, you must register for an account using an e-mail address associated with a 340B
        covered entity by whom you are employed or acting as an agent (“Covered Entity”) and on whose behalf you will be
        submitting de-identified 340B pharmacy claims data (“Covered Entity Claims Data”), or you must register using an
        invitation received from an Administrator Account (defined below) associated with the Covered Entity. Our 340B
        ESP™ Privacy Policy, available online at www.340besp.com/privacy or such other location as we may make available
        (“Privacy Policy”), and incorporated herein by reference, explains how we collect and use the Covered Entity
        Claims Data and User Information (defined below).
      </p>
      <p>
        These Terms are a legally binding agreement between Second Sight, the Covered Entity and its affiliates, your
        employer or principal if you are employed or acting as an agent for a legal entity other than the Covered Entity
        and such entity's affiliates, and you (“Agreement”). The terms “you” and “your” shall refer to the Covered
        Entity and its affiliates, your employer if a legal entity other than the Covered Entity, and you.
      </p>
      <p>
        BY USING THE COVERED ENTITY PLATFORM, CLICKING THE “ACCEPT” BUTTON, OR OTHERWISE AFFIRMING ACCEPTANCE YOU (A)
        ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS AND OUR PRIVACY POLICY; (B) REPRESENT THAT YOU HAVE
        THE AUTHORITY TO ENTER INTO THE AGREEMENT ON BEHALF OF THE COVERED ENTITY BY WHOM YOU ARE EMPLOYED OR ACTING AS
        AN AGENT TO PROVIDE COVERED ENTITY CLAIMS DATA, OR ON BEHALF OF YOUR EMPLOYER OR PRINCIPAL IF A LEGAL ENTITY
        OTHER THAN THE COVERED ENTITY, AND THAT ENTRY INTO THIS AGREEMENT HAS BEEN DULY AUTHORIZED BY THE COVERED ENTITY
        AND YOUR EMPLOYER; AND (C) YOU ACCEPT THIS AGREEMENT AND AGREE THAT YOU, THE COVERED ENTITY, AND YOUR EMPLOYER
        OR PRINCIPAL ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS OR DO NOT HAVE SUCH AUTHORITY,
        DO NOT USE THE COVERED ENTITY PLATFORM. PLEASE NOTE SECTION 6.2 CONTAINS INFORMATION ABOUT A DISCLAIMER; 6.3
        CONTAINS INFORMATION ABOUT LIABILITY LIMITS, SECTION 10 INCLUDES A FORUM SELECTION AND CHOICE OF LAW; AND
        SECTION 11.6 CONTAINS INFORMATION ABOUT HOW THESE TERMS CAN BE AMENDED.
      </p>
      <ol>
        <li id="section1">
          <strong>Data Collected By the Covered Entity Platform</strong>
          <ol>
            <li>
              <strong>Covered Entity Claims Data. </strong>The Covered Entity Platform enables you, on behalf of the
              Covered Entity, to use your computer under your control to (a) de-identify 340B pharmacy claims data into
              Covered Entity Claims Data that is fully de-identified as defined under the Health Insurance Portability
              and Accountability Act of 1996, as amended, and its implementing regulations (“HIPAA”); and (b)
              subsequently upload the Covered Entity Claims Data to Second Sight's servers for further processing. When
              you load 340B pharmacy claims data for prescriptions processed by the Covered Entity into your internet
              browser, the Covered Entity Platform software running locally within your internet browser (“Covered
              Entity Platform Browser Software”) first processes the data locally within your internet browser to enable
              the extraction and the generation of the data fields listed below, as specified by the National Council
              for Prescription Drug Program's (“NCPDP”) Field Numbers, and in accordance with the “Manufacturer Rebate
              Utilization, Plan, Formulary, Market Basket, and Reconciliation Flat File Standard” associated with such
              Field Numbers (when applicable), and Participating Pharmaceutical Manufacturers' policies. When you load
              340B pharmacy claims data into your internet browser for de-identification and subsequent upload, you will
              not be uploading any other data elements that are included in your data file because the Covered Entity
              Platform Browser Software excludes and does not collect such data elements.
              <ul>
                <li>
                  “NDC”, as specified by NCPDP Field # 407-D7, shall mean National Drug Code, a unique identifier of a
                  drug dispensed to a patient according to a prescription;
                </li>
                <li>
                  “Quantity Dispensed”, as specified by NCPDP Field # 442-E7, shall mean number of units dispensed to
                  the patient;
                </li>
                <li>
                  “Service Provider ID”, as specified by NCPDP Field # 201-B1, shall mean the unique identifier of the
                  pharmacy that filled the prescription;
                </li>
                <li>
                  “Service Provider ID Qualifier”, as specified by NCPDP Field # 202-B2, shall mean a code that
                  indicates the type of identifier included in the Service Provider ID field - e.g. a value of “01” if
                  the Service Provider ID is a National Provider Identifier (“NPI”);
                </li>
                <li>
                  “Prescriber ID”, as specified by NCPDP Field # 411-DB, shall mean the National Provider Identifier
                  (“NPI”) of the physician that wrote the prescription;
                </li>
                <li>
                  “Prescriber ID Qualifier”, as specified by NCPDP Field # 466-EZ, shall mean a code that indicates the
                  type of identifier included in the Prescriber ID field - e.g. a value of “01” if the Prescriber ID is
                  an NPI;
                </li>
                <li>
                  “Wholesaler Invoice Number” shall mean the identifier issued by a wholesaler for a replenishment order
                  made by the Covered Entity;
                </li>
                <li>
                  “Prescription Number” or “Rx Number”, as specified by NCPDP Field # 402-D2, shall mean an identifier
                  applied to a prescription by a pharmacy;
                </li>
                <li>
                  “Prescribed Date”, as specified by NCPDP Field #414-DE, shall mean date on which the prescription was
                  written by the physician;
                </li>
                <li>
                  “Date of Service” or “Fill Date”, as specified by NCPDP Field # 401-D1, shall mean date on which the
                  prescription was filled at the pharmacy;
                </li>
                <li>
                  “Contracted Entity ID” shall mean the Health Resources and Services Administration (“HRSA”) assigned
                  identifier of the 340B covered entity that designated the prescription as 340B and has a contract
                  pharmacy arrangement with the dispensing pharmacy; and
                </li>
                <li>
                  “Claim Conforms Flag” shall mean a true/false indication of whether a Date of Service occurs on or
                  after a threshold date set by a Participating Pharmaceutical Manufacturer, where this flag is
                  generated by comparing the Date of Service of each record to a threshold date set by a Participating
                  Pharmaceutical Manufacturer's policy.{" "}
                </li>
              </ul>
            </li>
            <li>
              <strong>De-Identification Method. </strong>The Covered Entity Claims Data is fully de-identified in
              accordance with the HIPAA expert determination method pursuant to 45 C.F.R. §164.514(b)(1) and does not
              contain any “Protected Health Information” or “PHI” as defined under HIPAA. The Covered Entity Platform
              Browser Software applies a SHA-3 hashing process with salting to each of the Prescription Number,
              Prescribed Date and Fill Date, where no party will have direct access to the salt key. You or a Covered
              Entity's Account holders may use the Covered Entity Platform Browser Software to upload the resultant
              de-identified data set as the Covered Entity Claims Data to Second Sight's servers for further processing.
              The Covered Entity Platform's processes adhere to the de-identification methodologies described in the
              expert determination. For additional information, please see the FAQs or request more information about
              the HIPAA expert determination by contacting us at support@340besp.com.
            </li>
            <li>
              <strong>Re-Identification. </strong>Second Sight shall not attempt to re-identify or de-anonymize any of
              the Covered Entity Claims Data, including, without limitation, attempting to correlate any such data with
              any number, identifier, characteristic or other information that could be used, alone or in combination
              with other information, to identify any individual patient.
            </li>
            <li>
              <strong>Data Accuracy. </strong>You represent and warrant that the 340B pharmacy claims data that you load
              into Covered Entity Platform Browser Software and the Covered Entity Claims Data that you submit through
              the Covered Entity Portal will be complete and accurate, and follow the format and instructions provided
              within the Covered Entity Portal, as noted in Sections 3.2, 4.2.4 and 4.4. If any data is provided in an
              incorrect format (e.g., a misidentification of a field) the de-identification process may not work as
              intended. If you inadvertently submit any PHI to the Covered Entity Platform, Second Sight shall upon
              discovery of such error delete the PHI from the Covered Entity Platform and notify you accordingly. Second
              Sight assumes no responsibility or liability for any errors in the data received from you, to the extent
              arising from errors in your records or from incorrect use of the Covered Entity Platform.
            </li>
            <li>
              You agree to grant us rights to use the Covered Entity Claims Data discussed in further detail below in
              Section 3.1.
            </li>
          </ol>
        </li>
        <li id="section2">
          <strong>Rights We Grant to You.</strong>
          <ol>
            <li>
              Subject to and conditioned upon your compliance with these Terms, Second Sight hereby grants a revocable,
              non-sublicensable, non-transferable, non-assignable, non-exclusive, limited right and license, to access
              and use the Covered Entity Platform for the Term of this Agreement (“Covered Entity Platform License”).
              This Covered Entity Platform License transfers to you neither title nor any proprietary or intellectual
              property rights to the Covered Entity Platform, related documentation, or any copyrights, patents, or
              trademarks, embodied therein or used in connection therewith, except for the rights expressly granted
              herein. Second Sight may terminate this Covered Entity Platform License at any time for any reason. All
              rights not expressly granted hereunder are reserved by Second Sight.
            </li>
            <li>
              Except as otherwise provided below, you agree that you will not yourself, or through any parent,
              subsidiary, affiliate, agent or other third party: (i) sell, lease, license or sublicense the Covered
              Entity Platform or any documentation, information, data or anything else accessible through the Covered
              Entity Platform; (ii) decompile, disassemble, or reverse engineer the Covered Entity Platform, in whole or
              in part; (iii) write or develop any derivative work based upon the Covered Entity Platform or any
              documentation, information, data, confidential information, or anything else accessible through the
              Covered Entity Platform; (iv) use the Covered Entity Platform in violation of any federal, state, or local
              law, regulation or rule (“Applicable Laws”); (v) use the Covered Entity Platform for purposes of
              competitive analysis of the Covered Entity Platform, the development of competing software products or
              services or any other purpose that is to the commercial disadvantage of Second Sight; (vi) provide,
              disclose, divulge or make available to, or permit access or use of the Covered Entity Platform, by any
              third party; (vii) retrieve, index, scrape, harvest, data mine or otherwise systematically gather or store
              content of the Covered Entity Platform; or (viii) remove any copyright, trademark or other proprietary
              notice or legend contained on (or printed from) the Covered Entity Platform.
            </li>
            <li>
              You acknowledge that Second Sight provides services to entities whose businesses may be competitive with
              yours. Nothing in this Covered Entity Platform License shall restrict Second Sight's ability to license
              the access and use of the Covered Entity Platform or provide any other services to any third parties,
              regardless of any potential conflict between such third parties and you.
            </li>
          </ol>
        </li>
        <li id="section3">
          <strong>Rights You Grant to Us.</strong>
          <ol>
            <li>
              <strong>Data License.</strong>You represent and warrant that you have any and all necessary right, title,
              license and authority (including any and all necessary permissions from third-party owners and rights
              holders) to provide the Covered Entity Claims Data to the Covered Entity Platform. You grant Second Sight
              a worldwide, sublicensable, non-exclusive, royalty-free, perpetual, irrevocable license to collect,
              process, disclose, create derivative works of and otherwise use the Covered Entity Claims Data (“Data
              License”) for the purposes set forth herein, including specifically pursuant to Sections 3.4 and 3.5, and
              represent and warrant that you are authorized to grant such Data License on behalf of the Covered Entity.
              This Data License survives after termination of the Agreement and shall survive as to any Covered Entity
              Claims Data that you have submitted on behalf of the Covered Entity or that a TPA (as defined herein) has
              submitted on behalf of the Covered Entity after such respective dates of submission. You further represent
              and warrant that you are under no obligation to any third party that would prohibit or interfere with
              entering into these Terms, providing such Covered Entity Claims Data, granting the Data License or
              otherwise performing under these Terms, and that entering into these Terms and the arrangements set forth
              in these Terms (including without limitation providing such data and granting the Data License) and
              performance hereunder will neither conflict with, result in, nor constitute a breach under any other
              agreement or arrangement to which you are a party.
            </li>
            <li>
              You assume responsibility for ensuring that data is entered correctly into the Covered Entity Platform
              Browser Software to ensure correct submission of Covered Entity Claims Data. You agree to follow all
              instructions provided within the Covered Entity Platform to provide 340B pharmacy claims data on behalf of
              the Covered Entity in the specified format to facilitate de-identification thereof and submission of
              Covered Entity Claims Data.
            </li>
            <li>
              You agree that Second Sight may use the Covered Entity Platform Browser Software to collect Covered Entity
              Claims Data for pharmaceutical manufacturers identified within the Covered Entity Platform (“Participating
              Pharmaceutical Manufacturers”). You agree that if the Covered Entity has authorized and consented to
              having one or more third party administrators submit Covered Entity Claims Data associated with such
              Covered Entity to Second Sight on its behalf (each, a “TPA”), Second Sight may reach out to such TPAs to
              collect such Covered Entity Claims Data from the TPAs. You may, on behalf of a Covered Entity, withdraw
              the authorization and consent for a TPA to provide Covered Entity Claims Data to Second Sight, with ten
              (10) business days advance written notice provided to Second Sight at the Notices contact provided below,
              and to the TPA.
            </li>
            <li>
              You agree that Second Sight may enable Covered Entity Claims Data to be combined with rebate data remitted
              to Participating Pharmaceutical Manufacturers by payers (including Medicaid, Medicare, TRICARE and
              commercial payers) (“Rebate Data”) in order to identify Ineligible Rebates and determine compliance with
              Participating Pharmaceutical Manufacturers' policies. You agree that Second Sight may enable reporting of
              the Ineligible Rebates, together with any other Covered Entity Platform Data (as defined below), to
              Participating Pharmaceutical Manufacturers, commercial payers, rebate claims processors or state Medicaid
              agencies. You acknowledge and agree that Participating Pharmaceutical Manufacturers, commercial payers,
              rebate claims processors or state Medicaid agencies may link the Covered Entity Claims Data with
              identifiable complete rebate data possessed by- such entities.{" "}
            </li>
            <li>
              You agree that Second Sight may disclose and sub-license the Covered Entity Claims Data and any other data
              derived from the interpretation, analysis, and combination of the foregoing data with other data (the
              “Covered Entity Platform Data”) to the Participating Pharmaceutical Manufacturers, commercial payers,
              rebate claims processors or state Medicaid agencies under the same Terms as applicable to us for the
              purpose of identifying Ineligible Rebates. Covered Entity Claims Data will only be disclosed or
              sub-licensed to the specific Participating Pharmaceutical Manufacturer that owns or markets the specific
              product included on the claim, and only for the purposes set forth herein, including specifically pursuant
              to Sections 3.4 and 3.5. Further, you agree that Second Sight may use the Covered Entity Platform Data for
              purposes of identifying Ineligible Rebates, improving the Covered Entity Platform and improving the
              process for identifying Ineligible Rebates.
            </li>
          </ol>
        </li>
        <li id="section4">
          <strong>Use of the Covered Entity Platform. </strong>Your access and use of the Covered Entity Platform shall
          be solely for your use on behalf of the Covered Entity. Upon registration, you will be assigned a User ID and
          you shall select a password to access and use the Covered Entity Platform (“Account”).
          <ol>
            <li>
              <strong>User Accounts. </strong>In order to register for an Account, you must either (a) use an email
              address having a domain name associated with the Covered Entity to register for an Account that can invite
              other users to register on behalf the Covered Entity (“Administrator Account”), or (b) register using an
              invitation from the Administrator Account of the Covered Entity. As part of the registration process you
              must provide your first name, last name, work e-mail address, and the Covered Entity on whose behalf you
              are accessing the Covered Entity Platform (“User Information”). You may provide your cell phone number as
              part of the registration process and User Information for authentication purposes. Your registration may
              be subject to our verification and further requests for information and may be denied for any reason or no
              reason.
              <ol>
                <li>
                  You consent to Second Sight contacting you via your work e-mail or if applicable via your cell phone
                  number (by call or text) or other User Information in order to authenticate your identity, provide
                  notifications regarding the Covered Entity Platform, and improve the services.
                </li>
                <li>
                  You consent to Second Sight sharing your User Information with participating pharmaceutical
                  manufacturers. You consent to said participating pharmaceutical manufacturers contacting you via your
                  User Information regarding the use of our Covered Entity Platform.
                </li>
                <li>
                  If you register using a work e-mail having a domain name associated with the Covered Entity, you
                  represent and warrant that you are an employee or agent of the Covered Entity. If you register via an
                  invitation by the Administrator Account using a work e-mail having a domain name different than the
                  Covered Entity, you represent and warrant that you are an employee or agent of the Covered Entity, or
                  an employee or agent of a legal entity that has been contracted and authorized by the Covered Entity
                  to provide Covered Entity Claims Data to the Covered Entity Platform on its behalf.
                </li>
              </ol>
            </li>
            <li>
              <strong>Conditions of Use. </strong>You agree to comply with these Terms as well as all Applicable Laws
              related to or in connection with the Covered Entity Platform, its use, and data provided to or displayed
              by the Covered Entity Platform.
              <ol>
                <li>
                  You (i) shall not provide access to your User ID or password to any third party; (ii) shall promptly
                  notify Second Sight of any unauthorized use of your User ID or password; and (iii) shall ensure that
                  you exit from your Covered Entity Platform account at the end of each session.
                </li>
                <li>You shall comply with the restrictions set forth in Section 2.2 above.</li>
                <li>
                  You agree, without limiting Second Sight's other rights and remedies, that you are responsible and
                  liable for your access to, and use of, the Covered Entity Platform, including any negligent acts or
                  omissions or your breach of any of these Terms.
                </li>
                <li>
                  You agree to timely submit Covered Entity Claims Data through the Covered Entity Platform in the
                  intervals specified therein, and to correct any errors in submission within the time period identified
                  below in Section 4.4 Monitoring.
                </li>
                <li>
                  If you are the registrant or account holder of an Administrator Account, you represent, warrant and
                  agree that you shall invite only individuals to the Covered Entity Platform that are employed by or
                  authorized to act as an agent of the Covered Entity associated with the Administrator Account for the
                  purposes of this Agreement.
                </li>
              </ol>
            </li>
            <li>
              <strong>Account Access and Termination. </strong>Second Sight reserves the right to deny access to any
              user, or terminate the use of any User ID and password, at any time in the event Second Sight determines
              in its sole discretion that unauthorized or otherwise inappropriate use of the Covered Entity Platform by
              a user has occurred or may occur, including but not limited to conduct in breach of these Terms or in
              violation of Applicable Laws.
            </li>
            <li>
              <strong>Monitoring. </strong>You acknowledge and grant us the right to (i) monitor your use of the Covered
              Entity Platform, (ii) monitor compliance with this Agreement, (iii) investigate any complaint or reported
              violation of our policies; or (iv) report any activity that we suspect may violate any law or regulation
              to regulators, law enforcement officials or other persons or entities that we deem appropriate. If any
              such monitoring reveals that you are not using the Covered Entity Platform in compliance with this
              Agreement, then you will remedy any such non-compliance within five (5) business days of receiving notice
              from us.
            </li>
            <li>
              <strong>Security and Confidentiality.</strong>Second Sight agrees to use appropriate administrative,
              physical, and technical safeguards to keep Covered Entity Claims Data secure and to prevent the use or
              disclosure of Covered Entity Claims Data other than as provided by this Agreement. For additional
              information, please see the FAQs.
            </li>
          </ol>
        </li>
        <li id="section5">
          <strong>Ownership of the Covered Entity Platform and Feedback. </strong>As between Second Sight and you,
          Second Sight retains sole and exclusive ownership of all right, title and interest in and to the Covered
          Entity Platform (including any improvements, copies, translations, modifications, adaptions or derivative
          works of the Covered Entity Platform) and to all copyrights, trade secrets, patents, trademarks, and other
          intellectual property rights therein and relating thereto. In addition, in the event you acquire any right,
          title or interest in the Covered Entity Platform, you hereby assign any such right title or interest to Second
          Sight. If you provide any feedback on the Covered Entity Platform, then we may use that feedback without
          obligation to you and you hereby assign all right, title and interest in such feedback to Second Sight.
        </li>
        <li id="section6">
          <strong>Warranty and Limitation of Liability.</strong>
          <ol>
            <li>
              <strong>Mutual Representation and Warranty. </strong>Each party represents and warrants to the other party
              that: (i) it is duly organized, validly existing, and in good standing under its jurisdiction of
              organization and has the right to enter into this Agreement; (ii) the execution, delivery, and performance
              of this Agreement and the consummation of the transactions contemplated hereby are within the corporate
              powers of such party and have been duly authorized by all necessary corporate action on the part of such
              party, and constitute a valid and binding agreement of such party; and (iii) it has the full power,
              authority, and right to perform its obligations and grant the rights it grants hereunder.
            </li>
            <li>
              <strong>Disclaimer of Warranties. </strong>YOU AGREE THAT THE COVERED ENTITY PLATFORM AND ANY SERVICES
              PROVIDED UNDER THESE TERMS BY SECOND SIGHT TO YOU SHALL BE ON AN “AS IS” BASIS. TO THE MAXIMUM EXTENT
              ALLOWED BY LAW, NEITHER SECOND SIGHT NOR ITS AFFILIATES MAKES ANY REPRESENTATION, WARRANTY, CONDITION,
              UNDERTAKING, OR TERM, WHETHER WRITTEN OR ORAL, EXPRESS OR IMPLIED, WITH RESPECT TO THE SUBJECT MATTER OF
              THIS LICENSE, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR IMPLIED WARRANTIES OF FITNESS FOR A
              PARTICULAR PURPOSE OR WARRANTY OF NON-INFRINGEMENT, AND ALL SUCH WARRANTIES ARE EXPRESSLY DISCLAIMED BY
              SECOND SIGHT. SECOND SIGHT DOES NOT WARRANT THAT THE COVERED ENTITY PLATFORM WILL BE UNINTERRUPTED, ERROR
              FREE OR WITHOUT DELAY, NOR DOES SECOND SIGHT MAKE ANY WARRANTY AS TO ANY RESULTS THAT MAY BE OBTAINED BY
              USE OF THE COVERED ENTITY PLATFORM.
            </li>
            <li>
              <strong>Limitations of Liability. </strong>YOU UNDERSTAND AND AGREE THAT SECOND SIGHT SHALL NOT BE LIABLE
              FOR ANY DAMAGES, ECONOMIC OR OTHER LOSS OR DAMAGE, WHETHER INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR
              PUNITIVE, AND EVEN IF SECOND SIGHT HAS BEEN ADVISED OF THE POSSIBILTY OF SUCH DAMAGES. THE LIMITATIONS OF
              LIABILITY SHALL NOT APPLY TO SECOND SIGHT'S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT. SECOND SIGHT'S
              AGGREGATE LIABILITY FOR DIRECT DAMAGES UNDER THIS AGREEMENT WILL NOT EXCEED ONE HUNDRED DOLLARS ($100).
            </li>
          </ol>
        </li>
        <li id="section7">
          <strong>Indemnification. </strong>
          <ol>
            <li>
              <strong>Indemnification by Covered Entity, you, your employer or principal. </strong>Covered Entity, you,
              and if applicable your employer or principal (“Covered Entity Indemnitors”) agree to defend, indemnify and
              hold harmless (including reasonable expenses, costs and attorneys' fees) Second Sight and its directors,
              officers, managers, employees, agents, representatives, successors and assigns (“Second Sight
              Indemnitees”) from any claims by third-parties, second-parties, government authorities, or actions arising
              from injury or damage to persons or tangible property resulting directly or indirectly from (i) your
              access or use or unauthorized access or use of the Covered Entity Platform, (ii) your breach of these
              Terms, (iii) any third party's access or use of the Covered Entity Platform permitted by you, or (iv) your
              sharing of Covered Entity Claims Data in breach of any agreements between you and a third party. Covered
              Entity Indemnitors will assume control of the defense and settlement of any claim subject to
              indemnification by Covered Entity Indemnitors; provided, however, that Second Sight may, at any time,
              elect to take control of the defense and settlement of any such claim without modifying or releasing your
              obligations hereunder. In any event, you will not settle any such claim without Second Sight's prior
              written consent.
            </li>
            <li>
              <strong>Indemnification by Second Sight. </strong>Second Sight agrees to defend, indemnify and hold
              harmless (including reasonable expenses, costs and attorneys' fees) Covered Entity Indemnitors and their
              directors, officers, managers, employees, agents, representatives, successors and assigns (“Covered Entity
              Indemnitees”) from any claims by third-parties, second-parties, government authorities, or actions arising
              from injury or damage to persons or tangible property resulting directly or indirectly from (i)
              unauthorized disclosure of Covered Entity Claims Data from the Covered Entity Platform or (ii) a security
              incident or breach impacting the Covered Entity Platform that results in an obligation to notify
              governmental authorities of such security incident or breach, provided that Covered Entity Indemnitors
              have complied and are in compliance with all Terms under this Agreement. Second Sight will assume control
              of the defense and settlement of any claim subject to indemnification by Second Sight; provided, however,
              that Covered Entity Indemnitees may, at any time, elect to take control of the defense and settlement of
              any such claim without modifying or releasing Second Sight's obligations hereunder. In any event, Second
              Sight will not settle any such claim without Covered Entity Indemnitees' prior written consent.
            </li>
          </ol>
        </li>
        <li id="section8">
          <strong>Delivery of Services.</strong>
          <ol>
            <li>
              You shall be solely responsible for any and all equipment, facilities, and/or connections necessary to
              enable delivery of services through the Covered Entity Platform to you. Second Sight is not responsible
              for supplying, monitoring, managing, securing, or maintaining any internet or network or for related
              performance, security, or availability issues.
            </li>
            <li>
              You will take appropriate steps, both before accessing and using the Covered Entity Platform and at all
              times thereafter, to copy and protect your own data and programs that may be lost, harmed or destroyed and
              to protect your equipment from any damage. You will be responsible for reconstruction, replacement, repair
              or recreation of lost programs, data or equipment in the event of any hardware, software, or services
              failure as a result of accessing or using the Covered Entity Platform. Second Sight will not, under any
              circumstances, be responsible for any such losses or damages.
            </li>
            <li>
              The Covered Entity Platform is subject to modification (including addition, alteration, or deletion) by
              Second Sight in its sole discretion.
            </li>
            <li>
              The availability of the Covered Entity Platform is not designed to be, nor is it intended to qualify as, a
              delivery of services on your behalf involving PHI.
            </li>
          </ol>
        </li>
        <li id="section9">
          <strong>Term and Termination. </strong>
          <ol>
            <li>
              Unless otherwise defined in the Agreement, the term of the Agreement and the Covered Entity Platform
              License granted hereunder shall commence upon your agreement to these Terms and will continue for
              recurring 30 day periods, unless the parties enter into a subsequent written agreement that modifies this
              term (the “Term”), Second Sight terminates your access, or your Covered Entity provides written notice to
              us pursuant to Section 11.12 of its intent to terminate the Agreement, which shall be effective thirty
              (30) days following receipt of the notice.
            </li>
            <li>
              Unless otherwise set forth in the Agreement, you understand that Second Sight may at any time and from
              time to time modify or discontinue access to the Covered Entity Platform (or any part), with or without
              notice, temporarily or permanently without liability to you or to any third party. Termination of the
              Covered Entity Platform License shall immediately and automatically terminate your right to access and use
              the Covered Entity Platform. Upon termination, you will promptly cease using the Covered Entity Platform
              and Second Sight may immediately terminate your access to the Covered Entity Platform and Second Sight
              shall have no obligation to return to you any data stored on Second Sight's systems.
            </li>
            <li>
              The sections of these Terms that by their nature survive expiration or termination shall survive any
              expiration or termination of the License. Notwithstanding, and without limiting the generality of, the
              foregoing sentence, the provisions in Sections 3, 4, 5, 6, 8, and 9 will survive termination of the
              Agreement and the Covered Entity Platform License.
            </li>
          </ol>
        </li>
        <li id="section10">
          <strong>Forum Selection and Choice of Law: </strong>The laws of Illinois, U.S.A., excluding Illinois's
          conflict of laws rules, will apply to any disputes arising out of or relating to these Terms. All claims
          arising out of or relating to these Terms will be litigated exclusively in the federal or state courts of the
          Northern District of Illinois, and you and Second Sight consent to personal jurisdiction in those courts.
        </li>
        <li id="section11">
          <strong>General Provisions.</strong>
          <ol>
            <li>
              <strong>Severability. </strong>If any provision of these Terms is held by a court of competent
              jurisdiction to be contrary to law, the remaining provisions of these Terms will remain in full force and
              effect. Without limiting the generality of the preceding sentence, if any remedy set forth in these Terms
              is determined to have failed of its essential purpose, then all other provisions of these Terms, including
              the limitation of liability and exclusion of damages shall remain in full force and effect.
            </li>
            <li>
              <strong>Third Party Beneficiaries and No Agency. </strong>Except for Participating Pharmaceutical
              Manufacturers that are identified upon logging in to the Covered Entity Platform, these Terms are for the
              sole benefit of the parties hereto and their respective successors and permitted assigns and nothing
              herein, express or implied, is intended to or shall confer on any other person any legal or equitable
              right, benefit or remedy of any nature whatsoever under or by reason of these Terms. You expressly
              acknowledge and agree that the Participating Pharmaceutical Manufacturers are third party beneficiaries of
              these Terms and have the full rights to enforce these Terms as if any one of them was a signatory hereto.
              These Terms do not create or imply any partnership, agency, or joint venture between the parties hereto.
            </li>
            <li>
              <strong>Headings. </strong>Section headings used herein are provided for convenience of reference only and
              shall not constitute a part of these Terms.
            </li>
            <li>
              <strong>Confidentiality. </strong>You may be given access to certain non-public proprietary information
              related to the Covered Entity Platform and Second Sight (the “Confidential Information”). You shall use
              this Confidential Information only as necessary in exercising the rights granted to you by these Terms.
              You shall not disclose any Confidential Information to any third party without our prior written consent
              and you agree that you will protect this Confidential Information from unauthorized use, access, or
              disclosure in the same manner that you would use to protect your own confidential and proprietary
              information of a similar nature and in any event with no less than a reasonable degree of care.
            </li>
            <li>
              <strong>Assignment. </strong>You may not assign, transfer or delegate any of the rights or obligations
              hereunder without the prior written consent of Second Sight. Notwithstanding the foregoing, this Agreement
              and the Data License granted to Second Sight may be assigned by Second Sight to an affiliate, a successor
              entity or any entity created or acquired as a result of a corporate reorganization, merger, acquisition or
              the purchaser of substantially all of the 340B ESP™ business line assets. You specifically authorize our
              use of subcontractors and our right to delegate any of the rights or obligations hereunder.
            </li>
            <li>
              <strong>Amendments. </strong>Second Sight may revise these Terms from time to time by posting such revised
              Terms on this website, or otherwise notifying you in advance of making effective such revisions to the
              Terms. Such revised Terms shall be effective to you upon the effective date provided in the posting or
              other notice, unless otherwise explicitly stated by Second Sight. It is your responsibility to be aware of
              any such revised terms by checking and reading these Terms from time to time and your notices. By
              continuing to access or use the Covered Entity Platform after such effective date, you agree to be bound
              by the revised Terms. If you do not agree with any of these Terms as they may be amended from time to
              time, you should request to deactivate your User ID and promptly cease using the Covered Entity Platform.
              If you do not agree with the revised Terms and cease using the Covered Entity Platform, Second Sight shall
              not use Covered Entity Claims Data or other data you have previously submitted to the Covered Entity
              Platform for additional purposes (if any) set forth in the revised Terms. Second Sight retains the right
              to continue using the Covered Entity Claims Data or other data you have previously submitted to the
              Covered Entity Platform under the Terms previously in effect.
            </li>
            <li>
              <strong>Force Majeure. </strong>We will not be deemed to be in breach of these Terms or liable for any
              breach of these Terms or our Privacy Policy due to any event or occurrence beyond our reasonable control,
              including without limitation, acts of God, terrorism, war, invasion, failures of any public networks,
              electrical shortages, earthquakes or floods, civil disorder, strikes, fire, pandemics or epidemics
              (whether or not already active at the time you accept these Terms), act of government (including but not
              limited to “shelter in place,” “travel ban,” “quarantine” or “shutdown” orders, whether or not already
              active at the time you accept these Terms) or other disaster.
            </li>
            <li>
              <strong>No Waiver. </strong>The failure of Second Sight to insist upon strict enforcement of any provision
              of these Terms shall not be construed as a waiver of any provision or right.
            </li>
            <li>
              <strong>Entire Agreement. </strong>These Terms, including any documents incorporated into these Terms by
              reference, constitute the entire agreement between you and Second Sight regarding the subject matter of
              these Terms and supersede all prior agreements and understandings, whether written or oral, or whether
              established by custom, practice, policy or precedent, with respect to the subject matter of the Terms.
            </li>
            <li>
              <strong>United States Only. </strong>The Covered Entity Platform is intended for use by individuals
              located in the United States and is not intended for use by anyone located outside of the United States.
              You should not use the Covered Entity Platform if you are not located in the United States. Second Sight
              makes no claims that the Covered Entity Platform is accessible or appropriate outside of the United
              States.
            </li>
            <li>
              <strong>Age Restrictions. </strong>The Covered Entity Platform is not directed to individuals under the
              age of 18. You must be at least 18 years of age to access and/or use the Covered Entity Platform. By using
              the Covered Entity Platform, you acknowledge and agree that you are at least 18 years of age.
            </li>
          </ol>
        </li>
        <li id="section12">
          <strong>Notices. </strong>
          Please contact us at:
          <br />
          <br />
          telephone: 1.888.398.5520 (toll free)
          <br />
          e-mail: support@340Besp.com
          <br />
          <br />
          Attn: Legal Department
          <br />
          Second Sight Solutions, LLC
          <br />
          70 W. Madison, Suite 5000 | Chicago, IL 60602
          <br />
          <br />
          All notices to you in connection with these Terms may be delivered via email at the email address provided by
          you, and you agree that these email communications satisfy any legal requirements.
        </li>
      </ol>
    </div>
  );
};

export default TermsOfService;
