const fieldDetails = [
  {
    name: "date_of_service",
    title: "Date of Service",
    description: "Date on which the patient filled their prescription.",
    required: true
  },
  {
    name: "date_prescribed",
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    required: true
  },
  {
    name: "rx_number",
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    required: true
  },
  {
    name: "ndc",
    title: "NDC",
    description: "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true
  },
  {
    name: "quantity",
    title: "Quantity",
    description: "The number of units in the prescription.",
    required: true
  },
  {
    name: "service_provider_id",
    title: "Service Provider ID",
    description: "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    required: true
  },
  {
    name: "contracted_entity_id",
    title: "Contracted Entity ID",
    description: "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    required: true
  },
  {
    name: "prescriber_id",
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    required: false
  },
  {
    name: "prescriber_id_qualifier",
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    required: false
  },
  {
    name: "service_provider_id_qualifier",
    title: "Service Provider ID Qualifier",
    description: "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    required: false
  },
  {
    name: "wholesaler_invoice_number",
    title: "Wholesaler Invoice Number",
    description: "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
    required: false
  },
];

const fieldsSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  prescriber_id_qualifier: null,
  service_provider_id: null,
  service_provider_id_qualifier: null,
  wholesaler_invoice_number: null,
  contracted_entity_id: null,
  claim_conforms_flag: null,
  formatted_rx_number: null,
}

const required = [
  "date_of_service",
  "date_prescribed",
  "rx_number",
  "ndc",
  "quantity",
  "service_provider_id",
  "contracted_entity_id"
]

const notRequired = ["prescriber_id", "prescriber_id_qualifier", "service_provider_id_qualifier", "wholesaler_invoice_number", "claim_conforms_flag", "formatted_rx_number"]

export const schema = () => {
  return fieldsSchema;
}

export const fields = () => {
  return fieldDetails;
};

export const fieldsNotRequired = () => {
  return notRequired;
};
